import Api from './Api';
import axios from 'axios';

export default {
  GetSeatLayout(payload) {
    return Api().post(`/api/external/seat-layout`, payload);
  },
  GetSeatType(payload) {
    return Api().get(`/api/external/seat-type/${payload.screen_id}`);
  },
  ReserveSeats(payload) {
    return Api().post(`/api/external/reserve-seat`, payload);
  },
  CinemaReserveSeats(payload) {
    return Api().post(`/api/cinema/admin/reserve-seats`, payload);
  },
  BookSeats(payload) {
    return Promise.reject(new Error('Not Implemented'));
  },
  CinemaBookSeats(payload) {
    return Promise.reject(new Error('Not Implemented'));
  },
  GetBookingDetails(payload) {
    const { bookingCode } = payload;
    return Api().get(`/api/external/booking-details/${bookingCode}`);
  },
  GetTicketPrice(payload) {
    return Api().get(
      `booking/ticket-price/${payload.session_id}/${payload.cinema_id}`,
    );
  },
  PrintTicket(payload) {
    return Api().get(
      `/api/pos/generate-ticket/${payload.booking_id}/${payload.cinema_id}`,
    );
  },
  PrintTicket2(payload) {
    return Api().get(
      `/api/pos/generate-receipt/${payload.sb_id}/${payload.ft_id}/${payload.cinema_id}?flag=BoxOfficeFnbTicket&isReprint=false`,
    );
  },
  CancelTransaction(payload) {
    return Api().get(`booking/cancel-transaction/${payload.reservation_id}`);
  },
  PickupTicket(payload) {
    return Api().post(`/api/external/pickup-ticket`, payload);
  },
  GetValidPaymentModes() {
    return Api().get(`/api/external/payment-modes/3`);
  },
  PaymentGateway(payload) {
    return Api().post(`/api/external/paymentGatewaypage/${payload.reservation_id}`);
  },
  PrintTicketElectron(payload) {
    return axios.post('http://localhost:3001/print_ticket', payload);
  },
  GetVoucherList(payload) {
    return Api().post(`/api/external/offers-vouchers`, payload);
  },
  ValidateVoucherCode(payload) {
    return Api().post(`/api/external/verify-voucher`, payload);
  },
  ReleaseRealtimeReservedSeats(payload) {
    return Api().post(`/api/external/release-realtime-seat`, payload);
  },
  GuestRegister(payload) {
    return Api().post('/api/guest-login', payload);
  },
  CreateCCAvenueRedirect(payload) {
    const { reservation_id } = payload;
    return Api().post(
      `/api/cinema/create-cc-avenue-intent/${reservation_id}`,
      payload,
    );
  },
  CheckBookingStatus(reservation_id) {
    return Api().get(
      `/api/external/booking_inquiry?reservation_id=${reservation_id}`,
    );
  },
};
